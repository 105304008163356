import { gql } from '@/__generated__/gateway';

export const createPostMutation = gql(`
  mutation CreatePost($context: ContextInput, $input: CreatePostInput) {
    community {
      createPost(context: $context, input: $input) {
        id
        body
        images
        published_at
        comments_count
        own_reaction
        author {
          id
          name
          username
          display_name
          avatar_url
          is_following
        }
        reaction_counts {
          love
          in_love
          smile
          cool
          haha
          wow
        }
        context {
          kind
          entity
          entity_id
          language
        }
        teaser {
          site_name
          url
          title
          type
        }
      }
    }
  }
`);

// TODO: How do I achieve this in the interface?
export const updatePostMutation = gql(`
  mutation UpdatePost($input: UpdatePostInput) {
    community {
      updatePost(input: $input) {
        id
        body
        images
      }
    }
  }
`);

export const deletePostMutation = gql(`
  mutation DeletePost($postId: ID!) {
    community {
      deletePost(post_id: $postId) {
        success
      }
    }
  }
`);

export const createCommunityImageUploadOptionsMutation = gql(`
  mutation CreateCommunityImageUploadOptions($input: UploadImageInput) {
    community {
      createImageUploadOptions(input: $input) {
        upload_endpoint
        file_url
      }
    }
  }
`);

export const createProfileImageUploadOptionsMutation = gql(`
  mutation CreateProfileImageUploadOptions($input: UploadImageInput!) {
    users {
      createImageUploadOptions(input: $input) {
        upload_endpoint
        file_url
      }
    }
  }
`);

// NOTE: setProfileAvatarMutation takes no id...
export const setProfileAvatarMutation = gql(`
  mutation SetProfileAvatar($input: SetAvatarInput!) {
    users {
      setAvatar(input: $input) {
        avatar_url
        success
      }
    }
  }
`);

// NOTE: but deleteProfileAvatarMutation requires an id?
export const deleteProfileAvatarMutation = gql(`
  mutation DeleteProfileAvatarMutation($uuid: String!) {
    users {
      deleteAvatar(uuid: $uuid) {
        success
      }
    }
  }
`);

export const createCommentMutation = gql(`
  mutation CreateComment($input: CreateCommentInput) {
    community {
      createComment(input: $input) {
        id
        body
        images
        author {
          id
          name
          username
          display_name
          avatar_url
          is_following
        }
        reaction_counts {
          love
          in_love
          smile
          cool
          haha
          wow
        }
        own_reaction
        published_at
        post_id
      }
    }
  }
`);

export const updateCommentMutation = gql(`
  mutation UpdateComment($input: UpdateCommentInput) {
    community {
      updateComment(input: $input) {
        id
        body
        images
      }
    }
  }
`);

export const deleteCommentMutation = gql(`
  mutation DeleteComment($commentId: ID!) {
    community {
      deleteComment(comment_id: $commentId) {
        success
      }
    }
  }
`);

export const createReactionMutation = gql(`
  mutation CreateReaction($input: CreateReactionInput) {
    community {
      createReaction(input: $input) {
        success
      }
    }
  }
`);

export const deleteReactionMutation = gql(`
  mutation DeleteReaction($input: DeleteReactionInput) {
    community {
      deleteReaction(input: $input) {
        success
      }
    }
  }
`);

export const followUserMutation = gql(`
  mutation Follow($input: FollowInput) {
    community {
      follow(input: $input) {
        success
      }
    }
  }
`);

export const unfollowUserMutation = gql(`
  mutation Unfollow($input: FollowInput) {
    community {
      unfollow(input: $input) {
        success
      }
    }
  }
`);

export const reportPostMutation = gql(`
  mutation ReportPost($input: ReportPostInput) {
    community {
      reportPost(input: $input) {
        success
      }
    }
  }
`);

export const reportCommentMutation = gql(`
  mutation ReportComment($input: ReportCommentInput) {
    community {
      reportComment(input: $input) {
        success
      }
    }
  }
`);

export const updateProfileMutation = gql(`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    users {
      updateProfile(input: $input) {
        success
      }
    }
  }
`);

export const acceptTosMutation = gql(`
  mutation AcceptTos($alias: String!) {
    toc {
      accept(alias: $alias) {
        success
      }
    }
  }
`);
